<template>
    <AppCarousel
        :title="title"
        identifier="review"
        :items="reviews"
        :separator="seperator"
        :gradient="gradient"
        :fade="fade"
        :breakpoints="{
            320: { slidesPerView: 'auto', spaceBetween: 10, centeredSlides: true },
            768: { slidesPerView: 4, spaceBetween: 10, centeredSlides: false },
            992: { slidesPerView: 4, spaceBetween: 10, centeredSlides: false },
            1400: { slidesPerView: 4, spaceBetween: 10, centeredSlides: false }
        }"
        :arrows="arrows"
        :infinite="infinite"
        :full-width="true"
        :mobile-width="mobileWidth"
        client:visible
    >
        <template #belowTitle>
            <slot />
        </template>
        <template #default="{ item }">
            <SingleReview
                :review="item"
                aria-label="Review"
                class="tw-h-full"
            />
        </template>
    </AppCarousel>
</template>

<script>
import AppCarousel from "@app/molecules/AppCarousel.vue";
import SingleReview from "@reviews/atoms/SingleReview.vue";

export default {
    components: {SingleReview, AppCarousel},
    props: {
        reviews: {
            type: Array,
            required: true
        },
        seperator: {
            type: Boolean,
            required: false,
            default: false
        },
        gradient: {
            type: Object,
            required: false,
            default: () => ({mobile: false, tablet: false, desktop: false})
        },
        fade: {
            type: Boolean,
            required: false,
            default: false
        },
        mobileWidth: {
            type: String,
            required: false,
            default: 'big'
        },
        arrows: {
            type: Object,
            required: false,
            default: () => ({mobile: true, tablet: true, desktop: true})
        },
        infinite: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>
