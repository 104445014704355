<template>
    <div class="tw-p-1 sm:tw-px-2.5 lg:tw-px-1">
        <div
            class="tw-px-[25px] tw-pt-[35px] tw-h-full tw-pb-[20px] tw-bg-white tw-border-2 tw-border-chards-silver-white tw-rounded-md tw-flex-grow tw-flex tw-flex-col"
        >
            <div>
                <div class="tw-justify-between tw-flex tw-w-full tw-items-center">
                    <div class="tw-flex tw-items-center tw-h-fit">
                        <template
                            v-for="(star, index) in review.rating"
                            :key="index"
                        >
                            <div
                                v-if="review.source === 'TRUSTPILOT'"
                                class="tw-bg-[url('/images/trustpilot-star.png')] tw-mx-[1px] tw-bg-contain tw-w-5 tw-h-5 tw-inline-block"
                            />
                            <div
                                v-else
                                class="tw-bg-[url('/images/trustspot-star.png')] tw-rounded-sm tw-bg-[#0279b7] tw-mx-[1px] tw-bg-contain tw-w-5 tw-h-5 tw-inline-block"
                            />
                        </template>
                    </div>
                    <AppText class="tw-h-fit tw-text-chards-purple tw-text-xs">
                        {{ formatDate(review.date) }}
                    </AppText>
                </div>
                <div class="tw-pt-8 tw-pb-8">
                    <AppText
                        family="serif"
                        class="tw-pb-1 tw-line-clamp-[1]"
                        tone="chards-dark"
                    >
                        {{ review.title }}
                    </AppText>
                    <AppText
                        class="tw-font-medium tw-tracking-wider tw-line-clamp-[2] tw-text-[#9e9ca1]"
                        size="xsmall"
                    >
                        {{ review.comments }}
                    </AppText>
                </div>
            </div>

            <AppText
                family="sans"
                class="tw-flex tw-justify-between tw-flex-wrap tw-mt-auto tw-text-chards-purple tw-text-xs"
            >
                {{ review.fullname }}
            </AppText>
        </div>
    </div>
</template>

<script>
import AppText from "@app/atoms/AppText.vue";
import { formatDate } from "@lib/Helpers.js";

export default {
    components: {AppText},
    props: {
        review: {
            type: Object,
            required: true
        }
    },
    methods: {
        formatDate
    }
};
</script>
